* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --header-bg: #0f0c19;
  --accent: #4e3aa3;
  --body-fg: #2f2c39;
}

body {
  font-family: sans-serif;
  background: #efefef;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background: var(--header-bg);
  width: 100%;
  text-align: center;
  color: white;
}
.banner {
  max-width: 100%;
}

@media (max-width: 640px) {
  nav ~ .banner {
    padding-top: 2rem;
  }
}

nav {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem 0;
  width: 100%;
}

nav img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

main {
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

select {
  font-size: 16px;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
}

input {
  padding: 12px 16px;
  background: white;
  border-radius: 8px;
  border: none;
}

#dropbtn,
#subdomain,
#value {
  width: 300px;
}

button {
  border-radius: 8px;
  border: none;
  background: var(--accent);
  color: white;
  padding: 12px 16px;
  font-size: 16px;
}

.btn-submit {
  border-radius: 8px;
  border: none;
  background: var(--accent);
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  background: green;
}

.btn-red {
  background: red;
}

.btn-green {
  background: green;
}

.btnBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

a {
  color: var(--accent);
}

footer {
  text-align: center;
  padding: 1rem;
}
